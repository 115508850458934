import styles from "@components/EllipsisText/style.module.less"
import { Link } from "gatsby"
import React, { useState } from "react"
import ReactTextMoreLess from "react-text-more-less"

export const EllipsisText = ({ postText, setUpdate, update }) => {
  const [collapsed, setCollapsed] = useState(true)

  const handleClickLink = (e) => {
    e.preventDefault()
  }

  if (!postText) return <div className={styles.enptyBlock}></div>

  return (
    <div className={styles.breakWords}>
      {postText}
      {/* <ReactTextMoreLess
        collapsed={collapsed}
        text={postText}
        lessHeight={25}
        showMoreText="... Ver mais"
        showMoreElement={
          <Link to="#" onClick={handleClickLink}>
            ... Ver mais
          </Link>
        }
        showLessElement={
          <Link to="#" onClick={handleClickLink} className="pl4">
            Ver menos
          </Link>
        }
        onClick={() => {
          setUpdate(!update)
          setCollapsed(!collapsed)
        }}
      /> */}
    </div>
  )
}
