import { Icon } from "@components/Icons"
import VideoWatermarkPlayer from "@components/VideoWatermarkPlayer"
import { useDimensions } from "@hooks/use-dimensions"
import { useResize } from "@hooks/use-resize"
import { useStores } from "@hooks/use-stores"
import { getWatermarkURL } from "@utils/path"
import { Modal } from "antd"
import cx from "classnames"
import React, { useEffect, useRef, useState } from "react"

import styles from "./style.module.less"

const getVideoDimensions = ({ screenWidth }) => {
  let videoWidth = 0
  let videoHeight = 0

  if (screenWidth >= 768 && screenWidth < 1024) {
    videoWidth = 0.95 * screenWidth - 335
    videoHeight = 600
  }

  if (screenWidth >= 1024 && screenWidth < 1327) {
    videoWidth = 0.95 * screenWidth - 335
    videoHeight = 600
  }

  if (screenWidth >= 1327) {
    videoWidth = 945
    videoHeight = 600
  }

  return [videoWidth, videoHeight]
}

const PostModalVideo = ({
  visible,
  handleCloseModal,
  getPost,
  item,
  likes,
  liked,
  affiliatedId,
  postId,
  handleLikeButton,
  isEssay,
  comments,
  onComment,
  setComments,
  onDeleteComment,
  onDeleteReply,
  isFavorite,
  getEssayPhotoVideoComments,
  totalComments,
  setTotalComments,
}) => {
  const { authSite, post } = useStores()
  const { user } = authSite
  const { getCloudinaryWatermarkLink } = post

  const [imageWidth, setImageWidth] = useState("100%")
  const [commentsHeight, setCommentsHeight] = useState("")
  const [imageWatermarkUrl, setImageWatermarkUrl] = useState(null)

  const videoRef = useRef(null)
  const imageRef = useRef(null)

  const imageSrc = getWatermarkURL(item.urlImage, user)
  const videoSrc = getWatermarkURL(item.urlVideo, user)

  const [screenWidth] = useResize()

  const [refDesktop] = useDimensions({
    timeout: 1000,
  })

  const [videoWidth, videoHeight] = getVideoDimensions({ screenWidth })

  const getCloudinaryImageURL = async () => {
    const imageLink = await getCloudinaryWatermarkLink(imageSrc)
    setImageWatermarkUrl(imageLink)
  }

  useEffect(() => {
    getCloudinaryImageURL()
  }, [item])

  useEffect(() => {
    const getComments = async () => {
      const {
        data: [post],
      } = await getPost({ postId: item.id })

      if (post) {
        setComments(post.comments)
      }
    }

    const getEssayFavoritesComments = async () => {
      const commentsData = await getEssayPhotoVideoComments(item.photoVideoId)

      setComments(commentsData)
      setTotalComments(
        commentsData.reduce(
          (acc, cur) =>
            acc + 1 + cur.replys.reduce((replyAcc) => replyAcc + 1, 0),
          0
        )
      )
    }

    if (getPost) getComments()

    if (isFavorite) getEssayFavoritesComments()
  }, [])

  if (!item) return null

  const isOnlyText = !item.urlImage && !item.urlVideo

  const modalContentWrapperClasses = cx("flex", {
    "flex-row": !isOnlyText,
    "flex-column": isOnlyText,
  })
  const maxWidth = isOnlyText ? { maxWidth: "500px" } : {}

  useEffect(() => {
    if (imageRef.current) {
      if (imageRef.current.naturalWidth > imageRef.current.naturalHeight) {
        setImageWidth("100%")
      } else {
        setImageWidth("auto")
      }
    }

    if (videoRef.current) {
      setCommentsHeight(videoRef.current.props.height)
    }
  }, [item.urlImage])

  // PEGA A URL DO VIDEO DA AWS E CRIA A CAPA DO VIDEO COM EXTENSAO .JPG
  const videoCover = `${process.env.S3_IMAGES}/${item.urlVideo}`
  const videoCoverUrl =
    videoCover.substr(0, videoCover.lastIndexOf(".")) + ".jpg"

  return (
    <Modal
      className={cx("videoModalPost", styles.videoModal)}
      visible={visible}
      width={item.urlImage ? "fit-content" : "100%"}
      centered
      footer={null}
      keyboard
      closeIcon={<Icon name="CloseModal" onClick={handleCloseModal} />}
      title={null}
      maskClosable
      destroyOnClose
      closable
      onCancel={handleCloseModal}
    >
      <div
        className="videoHeightPost"
        style={{ backgroundColor: "transparent" }}
      >
        {item.urlVideo && (
          <video
            // width={videoWidth}
            // height={videoHeight}
            controls={true}
            poster={videoCoverUrl}
            autoPlay={true}
            playsInline
            loop
            controlsList="nodownload"
          >
            <source
              src={`${process.env.S3_IMAGES}/${item.urlVideo}`}
              type="video/mp4"
            />
          </video>
        )}
      </div>
    </Modal>
  )
}

export default PostModalVideo
